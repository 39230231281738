import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));


//User Route
// const User = Loadable(lazy(() => import("views/utilities/users/user")));
// const AddUser = Loadable(lazy(() => import("views/utilities/users/add-user")));
// const EditUser = Loadable(
//   lazy(() => import("views/utilities/users/edit-user"))
// );

// Product routes

const AddProduct = Loadable(lazy(()=> import("views/utilities/ProductPages/AddProduct/index")))
const ProductDetail = Loadable(lazy(()=> import("views/utilities/ProductPages/ProductDetail/index")))
const ViewProduct = Loadable(lazy(()=> import("views/utilities/ProductPages/ViewProduct/index")))
const EditProduct = Loadable(
  lazy(() => import("views/utilities/ProductPages/EditProduct/index"))
);
const GetUser = Loadable(
  lazy(() => import("views/utilities/GetUser/index"))
);


const GetProductLead = Loadable(
  lazy(() => import("views/utilities/GetUser/productLead"))
);


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      children: [
        {
          path: "/AddProduct/index",
          element: <AddProduct />,
        },
        {
          path: "/ProductDetail/index",
          element: <ProductDetail />,
        },
        {
          path: "/ViewProduct/index/:id",
          element: <ViewProduct />,
        },
        {
          path: "/EditProduct/index/:id",
          element: <EditProduct />,
        },
      ],
    },
    {
      children: [
        {
          path: "/GetUser/index",
          element: <GetUser />,
        },
        {
          path: "/GetUser/ProductLead",
          element: <GetProductLead />,
        },
      ],
    },
  ],
};

export default MainRoutes;
