import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Party: [],
};

//internally using immer lib (can create mutable state)
export const partySlice = createSlice({
  name: "partyData",
  initialState,
  reducers: {
    updateAllParty: (state, action) => {
      state.Party = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllParty } = partySlice.actions;

// this is for configureStore
export default partySlice.reducer;
