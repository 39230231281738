// assets
import {
  IconArticle,
  IconShoppingCart,
  IconMap2,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconBuildingFactory,
} from "@tabler/icons";

// constant
const icons = {
  IconMap2,
  IconShoppingCart,
  IconArticle,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconHandFinger,
  IconBuildingFactory
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "product-List",
      title: "Products",
      icon: icons.IconShoppingCart,
      type: "collapse",
      children: [
        {
          id: "product-list",
          title: "Add Product",
          type: "item",
          url: "/AddProduct/index",
          breadcrumbs: true,
        },
        {
          id: "products",
          title: "Products",
          type: "item",
          url: "/ProductDetail/index",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "GetProductLead",
      title: "Product-Consultation",
      icon: icons.IconBuildingFactory,
      type: "item",
      url: "/GetUser/ProductLead",
      breadcrumbs: true,
    },
    {
      id: "GetUser",
      title: "Consultation",
      icon: icons.IconUsers,
      type: "item",
      url: "/GetUser/index",
      breadcrumbs: true,
    },
  ],
};

export default utilities;
