import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UserApi from "apis/user.api";
import { useAuthenticated } from "hooks/useAuthenticated.hook";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import { logout } from "utils/common.util";
import { toast } from "react-hot-toast";
import { getUserLocal } from "utils/localStorage.util";
import { IconLock } from "@tabler/icons";
// ==============================|| PROFILE MENU ||============================== //

// const ProfileSection = () => {
//   const theme = useTheme();
//   const userApi = new UserApi();
//   const customization = useSelector((state) => state.customization);
//   const navigate = useNavigate();
//   const [open, setOpen] = useState(false);
//   // const userId = useSelector((state) => state.user.v_user_info.userId);
//   const userId = getUserLocal();
//   /**
//    * anchorRef is used on different componets and specifying one type leads to other components throwing an error
//    * */
//   const anchorRef = useRef(null);

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }
//     setOpen(false);
//   };

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const prevOpen = useRef(open);
//   useEffect(() => {
//     if (prevOpen.current === true && open === false) {
//       anchorRef.current.focus();
//     }
//     prevOpen.current = open;
//   }, [open]);

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const isAuth = useAuthenticated();

//   const getUserById = useCallback(async () => {
//     try {
//       if (!isAuth) {
//         navigate("/");
//       } else {
//         const userData = await userApi.getUserById({ userId });
//         if (!userData || !userData.data.data) {
//           return toast.error("No Data  available");
//         } else {
//           setName(userData.data.data.name);
//           setEmail(userData.data.data.email);
//           return;
//         }
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Something went wrong");
//       throw error;
//     }
//   });

//   useEffect(() => {
//     getUserById();
//   }, []);

//   return (
//     <>
//       <Chip
//         sx={{
//           height: "48px",
//           alignItems: "center",
//           borderRadius: "27px",
//           transition: " .2s ease-in-out",
//           borderColor: theme.palette.primary.light,
//           backgroundColor: theme.palette.primary.light,
//           '&[aria-controls="menu-list-grow"], &:hover': {
//             borderColor: theme.palette.primary.main,
//             background: `${theme.palette.primary.main}!important`,
//             color: theme.palette.primary.light,
//             "& svg": {
//               stroke: theme.palette.primary.light,
//             },
//           },
//           "& .MuiChip-label": {
//             lineHeight: 0,
//           },
//         }}
//         icon={
//           <Avatar
//             src={""}
//             sx={{
//               ...theme.typography.mediumAvatar,
//               margin: "8px 0 8px 8px !important",
//               cursor: "pointer",
//             }}
//             ref={anchorRef}
//             aria-controls={open ? "menu-list-grow" : undefined}
//             aria-haspopup="true"
//             color="inherit"
//           />
//         }
//         label={
//           <IconSettings
//             stroke={1.5}
//             size="1.5rem"
//             color={theme.palette.primary.main}
//           />
//         }
//         variant="outlined"
//         ref={anchorRef}
//         aria-controls={open ? "menu-list-grow" : undefined}
//         aria-haspopup="true"
//         onClick={handleToggle}
//         color="primary"
//       />
//       <Popper
//         placement="bottom-end"
//         open={open}
//         anchorEl={anchorRef.current}
//         role={undefined}
//         transition
//         disablePortal
//         popperOptions={{
//           modifiers: [
//             {
//               name: "offset",
//               options: {
//                 offset: [0, 14],
//               },
//             },
//           ],
//         }}
//       >
//         {({ TransitionProps }) => (
//           <Transitions in={open} {...TransitionProps}>
//             <Paper>
//               <ClickAwayListener onClickAway={handleClose}>
//                 <MainCard
//                   border={false}
//                   elevation={16}
//                   content={false}
//                   boxShadow
//                   shadow={theme.shadows[16]}
//                 >
//                   <Box sx={{ p: 2 }}>
//                     <Stack>
//                       <Stack direction="row" spacing={0.5} alignItems="center">
//                         <Typography variant="h4">Wellcome Back,</Typography>
//                         <Typography
//                           component="span"
//                           variant="h4"
//                           sx={{ fontWeight: 400 }}
//                         >
//                           {name}
//                         </Typography>
//                       </Stack>
//                       <Typography variant="subtitle2">
//                         Email :- {email}
//                       </Typography>
//                     </Stack>
//                     <Divider />
//                   </Box>
//                   <PerfectScrollbar
//                     style={{
//                       height: "100%",
//                       maxHeight: "calc(100vh - 250px)",
//                       overflowX: "hidden",
//                     }}
//                   >
//                     <Box sx={{ p: 2 }}>
//                       <List
//                         component="nav"
//                         sx={{
//                           width: "100%",
//                           maxWidth: 350,
//                           minWidth: 300,
//                           backgroundColor: theme.palette.background.paper,
//                           borderRadius: "10px",
//                           [theme.breakpoints.down("md")]: {
//                             minWidth: "100%",
//                           },
//                           "& .MuiListItemButton-root": {
//                             mt: 0.5,
//                           },
//                         }}
//                       >
//                         <ListItemButton
//                           sx={{
//                             borderRadius: `${customization.borderRadius}px`,
//                           }}
//                           onClick={() => {
//                             logout(navigate);
//                           }}
//                         >
//                           <ListItemIcon>
//                             <IconLogout stroke={1.5} size="1.3rem" />
//                           </ListItemIcon>
//                           <ListItemText
//                             primary={
//                               <Typography variant="body2">Logout</Typography>
//                             }
//                           />
//                         </ListItemButton>
//                       </List>
//                     </Box>
//                   </PerfectScrollbar>
//                 </MainCard>
//               </ClickAwayListener>
//             </Paper>
//           </Transitions>
//         )}
//       </Popper>
//     </>
//   );
// };

// export default ProfileSection;
const ProfileSection = () => {
  const theme = useTheme();
  // const { borderRadius } = useConfig();
  const navigate = useNavigate();
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { logout, user } = useAuthenticated();
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    toast.success("Logout successfully")
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const userId = getUserLocal();
  console.log("userId", userId);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          // borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={""}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="24px"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Welcome Back,</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {userId?.UserName || "Admin"}
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">
                          {/* Email:- {userId?.Email || "Not available"} */}
                          Email:- {userId?.email || "admin@ashiyana.com"}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Box>
                    <PerfectScrollbar
                      style={{
                        height: "100%",
                        maxHeight: "calc(100vh - 250px)",
                        overflowX: "hidden",
                      }}
                    >
                      <Box sx={{ p: 2, pt: 0 }}>
                        <Divider />
                        <Card
                          sx={{
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? theme.palette.dark[800]
                                : theme.palette.primary.light,
                            my: 2,
                          }}
                        ></Card>
                        <Divider />
                        <List
                          component="nav"
                          sx={{
                            width: "100%",
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            // borderRadius: "10px",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "100%",
                            },
                            "& .MuiListItemButton-root": {
                              mt: 0.5,
                            },
                          }}
                        >
                          {/* <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="account-settings" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton> */}
                          {/* <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 1}
                                                        onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconUser stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Grid container spacing={1} justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="body2">
                                                                            <FormattedMessage id="social-profile" />
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Chip
                                                                            label="02"
                                                                            size="small"
                                                                            sx={{
                                                                                bgcolor:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? theme.palette.dark.dark
                                                                                        : theme.palette.warning.dark,
                                                                                color: theme.palette.background.default
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </ListItemButton> */}
                          <ListItemButton
                            // sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <IconLogout stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  {/* <FormattedMessage id="logout" /> */}
                                  Logout
                                </Typography>
                              }
                            />
                          </ListItemButton>
                          {/* <ListItemButton
                            // sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 4}
                            onClick={(event) =>
                              handleListItemClick(event, 0, "/change-passwrd")
                            }
                          >
                            <ListItemIcon>
                              <IconLock stroke={1.5} size="20px" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  <FormattedMessage id="Change Password" />
                                  Change Password
                                </Typography>
                              }
                            />
                          </ListItemButton> */}
                        </List>
                      </Box>
                    </PerfectScrollbar>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;

