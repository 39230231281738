export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/login",
      Method: HttpMethod.Post,
    },
  },
  Product: {
    AddProduct: {
      Endpoint: "/upload-project",
      Method: HttpMethod.Post,
    },
    ProductDetail: {
      Endpoint: "/projects",
      Method: HttpMethod.Post,
    },
    ViewProduct: {
      Endpoint: "/projectById",
      Method: HttpMethod.Post,
    },
    EditProduct: {
      Endpoint: "/edit-project",
      Method: HttpMethod.Post,
    },
    GetUser: {
      Endpoint: "/getUser",
      Method: HttpMethod.Post,
    },
    GetProductUser: {
      Endpoint: "/getProductUser",
      Method: HttpMethod.Post,
    },
    DeleteLead: {
      Endpoint: "/deleteLead",
      Method: HttpMethod.Post,
    },
    DeleteProduct: {
      Endpoint: "/delete-project",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
