import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux-slice/user.slice";
import customizationReducer from "store/customizationReducer";
import memberListReducer from "../redux-slice/member-list.slice";
import partyListReducer from "../redux-slice/party.slice";
import productListReducer from "../redux-slice/product-list.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    customization: customizationReducer,
    member: memberListReducer,
    party:partyListReducer,
    product:productListReducer,
  },
});
